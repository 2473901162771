<template>
    <div class="star-ratings">
        <div
            class="stars"
            ref="starsContainer"
            :style="{ pointerEvents: readOnly ? 'none' : 'auto' }"
            @click="onStarClick"
        >
            <div class="stars-outer" :style="{ color: inactiveColor }">
                <StarIcon
                    v-for="i in numberOfStars"
                    :key="i"
                    :style="{
                        ...generateSameWidthAndHeight(starSize),
                    }"
                >
                </StarIcon>
            </div>
            <div class="stars-inner" :style="{ width: percent, color: starColor }">
                <StarIcon
                    v-for="i in numberOfStars"
                    :key="i"
                    :style="{
                        ...generateSameWidthAndHeight(starSize),
                    }"
                >
                </StarIcon>
            </div>
        </div>
        <span v-if="$slots['votes']"><slot name="votes" /></span>
    </div>
</template>

<script>
    import StarIcon from './StarIcon.vue';

    export default {
        name: 'StarRating',
        components: { StarIcon },
        props: {
            starColor: {
                type: String,
                default: '#00A755',
            },
            inactiveColor: {
                type: String,
                default: '#E0E0E0',
            },
            starSize: {
                type: String,
                default: '18',
            },
            numberOfStars: {
                type: Number,
                default: 5,
            },
            modelValue: {
                type: Number,
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
            step: {
                type: Number,
                default: 1,
            },
        },
        emits: ['update:modelValue'],
        data() {
            return {
                rating: this.modelValue || 0,
            };
        },
        computed: {
            roundedRating() {
                return this.rounded(this.rating);
            },
            percent() {
                return (this.roundedRating / this.numberOfStars) * 100 + '%';
            },
        },
        watch: {
            modelValue(value) {
                this.rating = value;
            },
        },
        methods: {
            rounded(value, decimalPlaces = 0) {
                const power = 10 ** decimalPlaces;
                return Math.ceil(value * power) / power;
            },
            generateSameWidthAndHeight(size) {
                return {
                    width: size + 'px',
                    height: size + 'px',
                };
            },
            onStarClick(e) {
                const starsCont = this.$refs['starsContainer'];
                const relativeX = e.pageX - starsCont.offsetLeft;
                const roundedRating = this.rounded((relativeX / starsCont.offsetWidth) * this.numberOfStars);

                if (this.rating === roundedRating) {
                    this.rating = null;
                } else {
                    this.rating = roundedRating;
                }

                this.$emit('update:modelValue', this.rating);
            },
        },
    };
</script>

<style scoped lang="scss">
    .star-ratings {
        @include row-align-start;

        span {
            font-size: $font-14;
            margin-left: 12px;
        }
    }

    .stars {
        display: inline-block;
        position: relative;
        overflow: hidden;
    }

    .stars-outer,
    .stars-inner {
        height: inherit;

        svg {
            margin-right: 5px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .stars-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        max-width: 100%;
        min-width: 0;
        white-space: nowrap;
        transition: 320ms cubic-bezier(0.075, 0.82, 0.165, 1);
        overflow: hidden;
    }

    .stars svg {
        display: inline-block;
        fill: currentColor;
        cursor: pointer;
    }
</style>
