<template>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.5276 0.758542L12.8279 5.41323L17.9748 6.16171C18.8983 6.29516
            19.2682 7.4305 18.5988 8.08089L14.8758 11.6703L15.7564 16.7856C15.9149
            17.7105 14.9397 18.3993 14.122 17.9703L9.51865 15.5875L4.91669 18.0027C4.09755
            18.4316 3.12372 17.7425 3.28192 16.8179L4.16259 11.7027L0.438483 8.08159C-0.230541
            7.4312 0.139092 6.296 1.06251 6.16241L6.20939 5.41393L8.50966 0.759245C8.92451
            -0.0810948 10.1163 -0.0706534 10.5276 0.758542Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'StarIcon',
    };
</script>
