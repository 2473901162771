<template>
    <div class="meal-kit-radio-card-header">
        <div class="inner__wrapper">
            <div class="img" :style="backgroundStyles(image)" />
            <div class="content">
                <CRButton @click="openMealKitPage" class="name-btn" pattern="thin">
                    {{ $filters.getTranslationByKey(name) }}
                </CRButton>
                <CRTag> {{ mealsAmount }} {{ $t('common.meals') }} </CRTag>
            </div>
        </div>
        <CRButton @click="$emit('openCart')" add-to-cart />
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import { backgroundStyles } from '@/helpers/CssHelper';
    import { ConversionEventNameEnum } from '@/utils/enums';

    export default {
        name: 'MealKitRadioCardHeader',
        components: { CRButton, CRTag },
        props: {
            mealsAmount: {
                type: [Number, String],
                default: 0,
            },
            mealKitId: {
                type: Number,
                default: 0,
            },
            name: {
                type: Object,
                default: () => {},
            },
            image: {
                type: String,
                default: '',
            },
        },
        emits: ['openCart'],
        data() {
            return {
                backgroundStyles,
            };
        },

        methods: {
            async openMealKitPage() {
                this.$router.push({
                    name: 'mealKit',
                    params: { meal_kit_id: this.mealKitId },
                });

                fbq('track', ConversionEventNameEnum.VIEW_CONTENT, {
                    content_ids: [this.mealKitId],
                    content_type: 'product'
                });
        
            },
        },
    };
</script>

<style lang="scss" scoped>
    .meal-kit-radio-card-header {
        width: 100%;
        @include row-align-center-justify-between;

        .inner__wrapper {
            @include row-align-center;

            .img {
                width: 72px;
                height: 72px;
                background-image: url('../../assets/images/backgrounds/empty-mealKit-bg.png');

                border-radius: $br-10;
            }

            .content {
                @include column-align-start-justify-between;

                height: 72px;
                margin-left: 16px;
            }
        }
    }
</style>
