<template>
    <div class="weeks-menu" :class="{ 'empty-page': !shownRegularKits.length }">
        <div v-if="shownRegularKits.length">
            <h2>{{ $t('navbar.weeksMenu') }}</h2>
            <MenuButtons class="menu-btn__wrapper" @selectMenuType="handelSelectMenuType" :menu-type="menuType" />
            <div class="meal-kit-cards__wrapper" v-if="selectedMealKit">
                <CRRadio
                    class="radio-card"
                    v-for="mealKit in shownRegularKits"
                    v-model="selectedMealKit"
                    :key="mealKit.id"
                    :value="mealKit"
                    is-box
                >
                    <MealKitRadioCardHeader
                        class="card"
                        :meals-amount="mealKit.mealsAmount"
                        :name="mealKit.name"
                        :mealKit-id="mealKit.id"
                        :image="mealKit.pictures[0]?.picturePath.small"
                        @open-cart="openCart(mealKit)"
                    />
                    <template v-if="mealKit.id === selectedMealKit.id" #content>
                        <MealKitRadioCardCombinations
                            :combinations="mealKit.combinations"
                            @combinationIdChanged="combinationIdChanged"
                        />
                    </template>
                </CRRadio>
            </div>
            <div class="toggle__wrapper">
                <CRToggle v-model:checked="isAllergensShown" class="toggle-btn">
                    {{ $t('buttons.showAllergens') }}
                </CRToggle>
                <CRToggle v-model:checked="isCaloriesShown" :subtitle="$t('common.andNutrients')">
                    {{ $t('buttons.showCalorieNorm') }}
                </CRToggle>
            </div>
            <div class="radio__wrapper mt-30" v-if="!isUnique && selectedMealKit?.calories.length">
                <h5>{{ $t('mealKits.chooseCalorieSizeLabel') }}</h5>
                <CRRadio
                    v-for="calorie in selectedMealKit.calories"
                    :key="calorie.id"
                    :value="calorie"
                    v-model="selectedCalorie"
                    :active="selectedCalorie?.id === calorie.id"
                    class="radio-btn"
                >
                    {{ calorie.amount }}
                </CRRadio>
            </div>
            <div class="week-days-btn__wrapper">
                <CRButton
                    class="btn"
                    v-for="button in weekDaysButtonsData"
                    :key="button.value"
                    :active="selectedWeekDays.includes(button.value)"
                    @click="handelToggleWeekDay(button.value)"
                    pattern="section"
                >
                    {{ button.title }}
                </CRButton>
            </div>
            <div class="menu-section" v-if="currentWeekGroupedMenu && selectedCombination">
                <div
                    class="menu-section__inner"
                    :class="{ 'd-none': !selectedWeekDays.includes(menus[0]) }"
                    v-for="(menus, index) in menusList"
                    :key="index"
                >
                    <h3 class="mb-25 capitalize" v-if="provideApp.$screenInnerWidth >= 1024">
                        {{ $t(`weekDays.${menus[0]}`) }}
                    </h3>
                    <div v-for="(menu, index) in menus[1]" :key="index">
                        <CustomerDishCard
                            class="mb-20"
                            :selected-calorie="selectedCalorie"
                            v-if="selectedCombination.combination.includes(menu.mealType)"
                            :key="index"
                            :menu="menu"
                            :menu-name="$t(`common.${menu.mealType}`)"
                            :is-allergens-shown="isAllergensShown"
                            :is-evaluate-dish-disabled="evaluateDishSetting?.isDisabled"
                            :is-calories-shown="isCaloriesShown"
                            @openRatingModal="openRatingModal"
                        />
                    </div>
                </div>
            </div>
            <RateDishModal
                v-if="isRatingModalOpened"
                @close="isRatingModalOpened = false"
                :dish-id="editedMenu.dish.id"
                @updateDishRating="updateDishRating"
            />
        </div>
        <h2 class="text-center" v-else>{{ $t('mealKits.thereAreNoRegularMealKits') }}</h2>
    </div>
</template>

<script>
    import MealKitRadioCardCombinations from '@/components/MealKits/MealKitRadioCardCombinations.vue';
    import MealKitRadioCardHeader from '@/components/MealKits/MealKitRadioCardHeader.vue';
    import CustomerDishCard from '@/components/MealKits/CustomerDishCard.vue';
    import RateDishModal from '@/components/Modals/RateDishModal';
    import MenuButtons from '@/components/Common/Buttons/MenuButtons.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import { cartMixin, weeksMenuMixin } from '@/utils/mixins';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'WeeksMenu',
        components: {
            CRButton,
            CRRadio,
            CRToggle,
            MealKitRadioCardHeader,
            MealKitRadioCardCombinations,
            CustomerDishCard,
            RateDishModal,
            MenuButtons,
        },
        mixins: [weeksMenuMixin, cartMixin],
        data() {
            return {
                selectedMealKit: null,
                combinations: [],
                isUnique: false,
                selectedCalorie: null,
            };
        },
        computed: {
            ...mapGetters('mealKit', ['shownRegularKits']),
        },
        watch: {
            selectedMealKit() {
                if (this.shownRegularKits.length) {
                    this.combinations = this.selectedMealKit.combinations;
                    this.selectedCombination = this.combinations[0];
                    this.selectedCombinationId = this.selectedCombination.id;
                }
            },
            shownRegularKits(array) {
                this.selectedMealKit = array[0] ?? null;
                if (this.shownRegularKits.length) {
                    this.combinations = this.selectedMealKit.combinations;
                }
            },
        },
        methods: {
            ...mapActions('mealKit', ['getAllMealKits']),

            combinationIdChanged(value) {
                this.selectedCombinationId = value;
            },
        },
        async mounted() {
            try {
                await this.getAllMealKits();
                this.selectedCalorie = this.selectedMealKit?.calories[0] ?? null;
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .weeks-menu {
        .radio__wrapper {
            h5 {
                margin-bottom: 10px;
            }

            .radio-btn {
                margin-bottom: 5px;
            }
        }
        padding-top: 25px;
        padding-bottom: 25px;

        h2 {
            width: 100%;
            margin-bottom: 20px;
        }

        .menu-btn__wrapper {
            margin-top: 15px;
        }

        .meal-kit-cards__wrapper {
            margin-top: 20px;

            .radio-card {
                @include row-align-center-justify-between;
                flex-wrap: wrap;
                margin-bottom: 16px;

                &:deep(.cr-radio__text) {
                    width: 90%;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .toggle__wrapper {
            margin-top: 15px;
            margin-bottom: 20px;

            .toggle-btn {
                margin-bottom: 10px;
            }
        }

        .week-days-btn__wrapper {
            @include row-align-center;

            width: 100%;
            margin-top: 35px;
            margin-bottom: 10px;

            .btn {
                margin-right: 10px;
                cursor: pointer;
                pointer-events: all;
            }
        }
    }

    @include media($sm) {
        .weeks-menu {
            .meal-kit-cards__wrapper {
                @include row-align-start;
                flex-wrap: wrap;

                .radio-card {
                    width: 49%;

                    margin-right: 2%;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    @include media($md) {
        .weeks-menu {
            .radio__wrapper {
                @include row-align-center;
                flex-wrap: wrap;
                width: 100%;

                h5 {
                    margin-right: 10px;
                }

                .radio-btn {
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }
            .toggle__wrapper {
                @include row-align-start;

                .toggle-btn {
                    margin-bottom: 0;
                    margin-right: 20px;
                }
            }

            .menu-section {
                @include row-align-stretch-justify-between;
                width: 100%;

                .menu-section__inner {
                    width: 49%;
                }
            }
        }
    }

    @include media($lg) {
        .weeks-menu {
            padding-top: 50px;

            h2 {
                font-size: $font-34;
                margin-bottom: 34px;
            }

            .meal-kit-cards__wrapper {
                @include row-align-start;
                flex-wrap: wrap;

                .radio-card {
                    width: 32%;

                    &:nth-of-type(2n) {
                        margin-right: 2%;
                    }

                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }
                }
            }

            .week-days-btn__wrapper {
                margin-bottom: 30px;
            }
        }
    }

    @include media($xl) {
        .weeks-menu {
            .meal-kit-cards__wrapper {
                @include row-align-start;
                flex-wrap: wrap;

                .radio-card {
                    width: 24%;

                    margin-right: 2%;

                    &:nth-of-type(3n) {
                        margin-right: 2%;
                    }

                    &:nth-of-type(4n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>
