<template>
    <div class="card">
        <div class="card__inner" v-if="menu.dish">
            <div class="header">
                <h5>{{ menuName }}</h5>
                <StarRating v-if="!isEvaluateDishDisabled" read-only :modelValue="rating" />
            </div>
            <p>{{ $filters.getTranslationByKey(menu.dish.description) }}</p>
            <div v-if="isCaloriesShown" class="tags__wrapper">
                <CRTag class="tag">
                    {{ getNutrientLabel('calories') }}
                </CRTag>
                <CRTag class="tag">
                    {{ getNutrientLabel('carbohydrates') }}
                </CRTag>
                <CRTag class="tag">
                    {{ getNutrientLabel('fats') }}
                </CRTag>
                <CRTag class="tag">
                    {{ getNutrientLabel('proteins') }}
                </CRTag>
            </div>
            <div v-if="isAllergensShown">
                <div v-if="menu.dish.allergens.length" class="tags__wrapper">
                    <CRTag
                        class="tag"
                        type="secondary"
                        icon="vegetarian"
                        v-for="allergen in menu.dish.allergens"
                        :key="allergen.id"
                    >
                        {{ $filters.getTranslationByKey(allergen.name) }}
                    </CRTag>
                </div>
                <p class="no-items" v-else>{{ $t('common.noAllergensDefined') }}</p>
            </div>
            <CRButton v-if="user" pattern="thin" @click="$emit('openRatingModal', menu)">
                {{ $t('buttons.evaluateDish') }}
            </CRButton>
        </div>
        <div v-else>
            <div class="header">
                <h5>
                    {{ $t(`common.${menu.mealType}`) }}
                </h5>
            </div>
            <p class="no-items">
                {{ $t('dishes.dishNotSpecified') }}
            </p>
        </div>
    </div>
</template>

<script>
    import StarRating from '@/components/Common/Rating/StarRating.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'CustomerDishCard',
        components: { StarRating, CRTag, CRButton },
        props: {
            menu: {
                type: Object,
                default: () => {},
            },
            selectedCalorie: {
                type: Object,
                default: () => {},
            },
            menuName: {
                type: String,
                default: '',
            },
            isAllergensShown: {
                type: Boolean,
                default: false,
            },
            isEvaluateDishDisabled: {
                type: Boolean,
                default: false,
            },
            isCaloriesShown: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['openRatingModal'],
        computed: {
            ...mapGetters('auth', ['user']),

            rating() {
                let updatedRating = this.menu.dish.rating + 1;
                return updatedRating > 5 ? this.menu.dish.rating : updatedRating;
            },
        },

        methods: {
            getNutrientLabel(nutrient) {
                const neededConf = this.menu?.dish.macronutrients?.find(
                    (macronutrient) => macronutrient.calorieId === this.selectedCalorie.id
                );

                return neededConf?.configuration?.[nutrient]
                    ? this.$t(`dishes.${nutrient}`, { amount: neededConf?.configuration?.[nutrient] })
                    : this.$t(`dishes.${nutrient}NotSpecified`);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .card {
        background: $white;

        box-shadow: $box-shadow-dark;
        border-radius: $br-10;

        padding: 20px;
        padding-bottom: 10px;

        .header {
            @include row-align-center-justify-between;

            margin-bottom: 10px;
        }

        p {
            margin-bottom: 12px;
        }

        .tags__wrapper {
            @include row-align-center;

            flex-wrap: wrap;

            .tag {
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }

        .no-items {
            font-style: italic;
            margin-bottom: 5px;
        }
    }
</style>
