<template>
    <div class="meal-kit-radio-card">
        <CRRadio
            v-for="combination in combinations"
            v-model.number="combinationId"
            :key="combination.id"
            :value="combination.id"
            class="radio-btn"
            @change="combinationIdChanged"
        >
            {{ $filters.getTranslationByKey(combination.name) }}
        </CRRadio>
    </div>
</template>

<script>
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';

    export default {
        name: 'MealKitRadioCardCombinations',
        components: { CRRadio },
        props: {
            combinations: {
                type: Array,
                default: () => [],
            },
        },
        emits: ['combinationIdChanged'],
        data() {
            return {
                combinationId: this.combinations[0].id,
            };
        },
        methods: {
            combinationIdChanged(value) {
                this.$emit('combinationIdChanged', value);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .meal-kit-radio-card {
        @include column-align-start-justify-between;

        border-top: 1px solid $grey-line;
        padding-top: 10px;

        .radio-btn {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>
