<template>
    <BasicModal @close="$emit('close')" is-rating-modal>
        <div class="container">
            <div />
            <div class="container__inner">
                <h2 class="mb-10">{{ $t('common.rateDish') }}</h2>
                <StarRating v-model="rating" starSize="40" />
            </div>
            <div>
                <CRButton class="close-btn" close @click="$emit('close')" />
            </div>
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import StarRating from '@/components/Common/Rating/StarRating.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { DishReviewApi } from '@/api';

    export default {
        name: 'RateDishModal',
        components: { BasicModal, StarRating, CRButton },
        props: {
            dishId: {
                type: Number,
                default: null,
            },
        },
        emits: ['close', 'updateDishRating'],
        data() {
            return {
                rating: 0,
            };
        },
        watch: {
            async rating(value) {
                try {
                    const data = {
                        rating: value,
                        dishId: this.dishId,
                    };

                    const response = await DishReviewApi.store(data);
                    this.$emit('updateDishRating', response.data);
                } catch (error) {
                    this.$filters.toast(error.message);
                } finally {
                    this.$emit('close');
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include row-align-start-justify-between;

        width: 100%;
        max-width: 560px;

        padding: 48px 23px;
        padding-top: 13px;

        background: #ffffff;
        border-radius: $br-10;

        .container__inner {
            margin-top: 50px;
        }
    }

    @include media($lg) {
        .container {
            padding: 60px;
            padding-top: 13px;
            padding-right: 13px;
        }
    }
</style>
