<template>
    <div class="btn__wrapper">
        <CRButton
            class="btn"
            v-for="button in menuButtonsData"
            :key="button.value"
            :active="menuType === button.value"
            @click="handelSelectMenuType(button.value)"
            pattern="section"
        >
            {{ button.title }}
        </CRButton>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { menuButtonsData } from '@/config/dishes/dishes';

    export default {
        name: 'MenuButtons',
        components: { CRButton },
        props: {
            menuType: {
                type: String,
                default: '',
            },
        },
        emits: ['selectMenuType'],
        data() {
            return {
                menuButtonsData,
            };
        },
        methods: {
            handelSelectMenuType(value) {
                this.$emit('selectMenuType', value);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .btn__wrapper {
        @include row-align-center;

        .btn:first-child {
            margin-right: 8px;
        }
    }
</style>
