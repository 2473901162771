import { translation } from '@/helpers/TranslationHelper';
import { MenuTypeEnum } from '@/utils/enums';

export const menuButtonsData = [
    { title: translation('common.regular'), value: MenuTypeEnum.REGULAR },
    { title: translation('common.pescatarian'), value: MenuTypeEnum.VEGETARIAN },
];

export const dishMealButtonsData = [
    { title: translation('common.breakfast'), value: 'breakfast' },
    { title: translation('common.lunch'), value: 'lunch' },
    { title: translation('common.dinner'), value: 'dinner' },
    { title: translation('common.snack'), value: 'snack' },
];

export const mealTypeFilterData = [
    { value: '', label: translation('common.none') },
    { value: 'breakfast', label: translation('common.breakfast') },
    { value: 'lunch', label: translation('common.lunch') },
    { value: 'dinner', label: translation('common.dinner') },
    { value: 'snack', label: translation('common.snack') },
    { value: 'null', label: translation('common.undefined') },
];

export const sortByFilterData = [
    { value: 1, label: translation('common.newestCreated'), field: 'created_at', fieldValue: 'desc' },
    { value: 2, label: translation('common.oldestCreated'), field: 'created_at', fieldValue: 'asc' },
    {
        value: 3,
        label: translation('common.highestEvaluated'),
        field: 'average_rating',
        fieldValue: 'desc',
    },
    { value: 4, label: translation('common.lowestEvaluated'), field: 'average_rating', fieldValue: 'asc' },
];
